import { PluginName } from 'editor/src/store/plugins/types';

import { ShutterstockImageItem } from 'editor/src/component/DesktopSidebar/TabContents/ShutterstockTabContent/types';

export interface GalleryState {
  images: GalleryImage[];
  hideUsedPhotos: boolean;
  sessionImages: string[];
  selectedImagesIds: string[];
}

export enum MimeType {
  'SVG' = 'SVG',
  'PDF' = 'PDF',
  'JPG' = 'JPG',
  'PNG' = 'PNG',
  'WebP' = 'WebP',
  'Unknown' = 'Unknown',
}
export interface GalleryImage {
  id: string;
  uploaderId?: string;
  uuid: number;
  name: string;
  type: MimeType;
  width: number;
  height: number;
  hasAssetDimensions: boolean;
  state: ImageState;
  url?: string;
  thumbnailUrl?: string;
  quantizedUrl?: string;
  previewData?: string;
  label?: string;
  bytesTotal?: number;
  bytesUploaded?: number;
  source?: string;
  colors?: string[];
  quantizedColors?: string[];
  hidden?: true;
  from?: { type: PluginName.Graphics; addonId: string };
  hideProgressPercentage?: boolean;
  exif?: ExifData;
  digitizationStatus?: AssetDigitizationStatus;
}

export enum AssetDigitizationStatus {
  DIGITIZING = 'digitizing',
  DIGITIZED = 'digitized',
  FAILED = 'failed',
}

export interface LocalPreviewImageData extends BaseImage {
  preview: string | undefined;
  type: string;
  name: string;
}

export interface RemotePreviewImageData extends BaseImage {
  preview: string;
  type: string;
  name: string;
}

export interface UploadedImageData {
  file: BaseImage;
  url: string;
  thumbnailUrl: string | undefined;
  quantizedUrl?: string;
  mimeType: string;
  hidden?: true;
}

export interface BaseImage {
  id: string;
  name?: string;
  type?: string;
  width?: number;
  height?: number;
  label?: string;
  source?: string;
  colors?: ImageColors;
  quantizedColors?: string[];
  hideProgressPercentage?: boolean;
  exif?: ExifData;
  digitizationStatus?: AssetDigitizationStatus;
}

export interface ExifData {
  dateCreated?: string;
  location?: {
    latitude: number;
    longitude: number;
  };
}

export interface FailedUploadImageData {
  file: BaseImage;
}

export interface ProgressImageData {
  file: BaseImage & { preview: string | undefined; isRemote: boolean };
  bytesTotal: number;
  bytesUploaded: number;
}

export interface ShutterstockImageData {
  assetProviderUid: string;
  image: ShutterstockImageItem;
}

export enum ImageState {
  UPLOADED = 'UPLOADED',
  LOCAL_DROP = 'LOCAL_DROP',
  LOCAL_PREVIEW = 'LOCAL_PREVIEW',
  REMOTE_PREVIEW = 'REMOTE_PREVIEW',
  FAILED = 'FAILED',
}

export interface ImageColors {
  fill?: string[];
  stroke?: string[];
}

export interface UploadedAsset {
  pluginAssetId: string;
  assetId: string;
  url: string;
  thumbnailUrl: string;
  mimeType: string;
  colors?: ImageColors;
  quantizedColors?: string[];
  width: number;
  height: number;
  label: string;
}
